import React, { ReactNode } from 'react';
import {
  OnLinkClickType,
  S4AMastheadProvider,
} from '@spotify-internal/s4x-masthead';
import { getSpotifyClientId } from '../@mrkt/features/env';
import { useLanguageSelectionContext } from '../../features/src/i18n/components/LanguageSelection';
import { useLocale, useRtl } from '../../features/src/i18n';
import { getLanguage } from './getLanguage';
import { GetLinkPropsType } from '@spotify-internal/frodor-modules';
import { getInternalUrl } from './getInternalUrl';
import { useRouter } from 'next/router';
import { useTracking } from '../features/Tracking';

export const MastheadProvider = ({
  children,
  contentfulEntryId,
}: {
  children: ReactNode;
  contentfulEntryId?: string;
}) => {
  const locale = useLocale();
  const rtl = useRtl();
  const { dispatch } = useLanguageSelectionContext();
  const router = useRouter();
  const { trackLinkClick } = useTracking();
  return (
    <S4AMastheadProvider
      getLinkProps={({
        href,
        trackingAttributes,
        openInNewTab,
      }: GetLinkPropsType) => {
        const internalUrl = href && getInternalUrl(href);
        return {
          onClick: e => {
            trackLinkClick({
              trackingAttributes,
              external: openInNewTab,
              href,
            });
            if (!openInNewTab && internalUrl && !e.metaKey) {
              router.push(internalUrl);
            }
            return {};
          },
          href: internalUrl || href,
          target: openInNewTab ? '_blank' : '_self',
        };
      }}
      onLinkClick={(link: OnLinkClickType) => {
        trackLinkClick(link);
        if (!link.external && link.href.startsWith('/')) {
          router.push(link.href);
        }
      }}
      isFrodor
      clientId={getSpotifyClientId()}
      language={{
        dir: rtl ? 'rtl' : 'ltr',
        locale: getLanguage(locale),
        onClick: () => dispatch({ type: 'open' }),
        marketSelectReturnToPath: '',
        useCountryRegionPicker: false,
      }}
      contentfulEntryId={contentfulEntryId}
    >
      {children}
    </S4AMastheadProvider>
  );
};
